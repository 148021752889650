.borderTitle{
    text-align:start;
    margin-top: -20px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
}

.borderTitle span{
    background-color: white;
}

.borderTitle2{
    
    margin-top: -10px;
    margin-left: 10px;
    height: 20px;
    line-height: 20px;
    width: 100%;
    text-align:start !important;
    font-size: 20px;
}

.borderTitle2 span{
    background-color: white;
}

.mainTitle {
    margin-top: -33px;
    margin-left: 20px;
    height: 20px;
    line-height: 20px;
    width: 100%;
    text-align:start !important;
    font-size: 20px;
}

.mainTitle span{
    background-color: white;
}.cancelCollection {
    margin-top: -33px;
    margin-right: 10px;
    height: 20px;
    line-height: 20px;
    width: 100%;
    text-align:end !important;
    font-size: 20px;
    cursor: pointer;
    color: red;
}

.cancelCollection span{
    background-color: white;
}