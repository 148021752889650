.reddot{
    height: 15px;
    width: 15px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}

.greendot{
    height: 15px;
    width: 15px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}